import React from 'react'

// import "../../../../style/css/textDogovor.css"

function TextDogovor(){

    return(
        <div className="textDogovorBlock">
            <div className="textDogovor">
            <h1>Правила публичной оферты и конфедициальности</h1>
            <div className="blocktextDogov">
                <h2>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ <br /> об оказании услуг посредством онлайн платформы</h2>
                <h3>г. Ташкент <span> «____» _____ 20__г.</span></h3>
                    <div className="textD_Block1">
                        <h1>1. ОБЩИЕ ПОЛОЖЕНИЯ</h1>
                        <ul>
                            <li>1.1. Данный документ является официальным предложением (публичной офертой) ООО «ASIA CAMPUS» (далее – Правообладатель платформы). Он содержит все существенные условия оказания услуг посредством облачной платформы, предназначенной для организации и автоматизации онлайн подачи документов в различные зарубежные ВУЗы. (далее - Услуги), доступ к которому обеспечивается через платформу https://edugate.uz (далее – Платформа).</li>
                            <li>1.2. Получение доступа к  Платформе по настоящей Оферте признается ее Акцептом, т.е. полным и безоговорочным принятием условий данной Оферты.</li>
                            <li>1.3. В соответствии с частью 4 статьи 370 Гражданского кодекса Республики Узбекистан в случае принятия изложенных ниже условий физическое лицо, производящее акцепт этой оферты, становится пользователем Платформой (далее – Заказчик). Правообладатель платформы и Заказчик в настоящей Оферте также совместно именуются Сторонами.</li>
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>2. ПРЕДМЕТ ДОГОВОРА ПУБЛИЧНОЙ ОФЕРТЫ</h1>
                        <ul>z
                            <li>2.1. Правообладатель платформы обязуется предоставить Заказчику доступ  к Платформе для подачи документов в иностранные государственные и негосударственные Высшие учебные заведения (далее - ВУЗы) зарубежных стран. </li>
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>2.2. Процедура оказания Услуг посредством Платформы:</h1>
                        <ul>
                            <li>2.2.1. Правообладатель платформы предоставляет либо Заказчик сам самостоятельно через сеть интернет находит доступ к  Платформе;</li>
                            <li>2.2.2. Заказчик самостоятельно выбирает ВУЗ и направление;</li>
                            <li>2.2.3. Платформа посредством искусственного интеллекта посчитает сумму оказываемых Услуг и выставляет счёт на оплату Услуг;</li>
                            <li>2.2.4. Заказчик уплачивает стоимость Услуг с помощью ЭПС (Click, Payme, путем банковского перечисления или иным путем);</li>
                            <li>2.2.5. Заказчик загружает документы (дипломы, аттестаты, копии паспорта и т.д.) в электронном виде;</li>
                            <li>2.2.6. После отправки Заказчиком документов, Платформа отправляет документы нотариусу либо сам нотариус получает загруженные документы на перевод или апостиль. (За нотариальные действия, совершенные нотариусом Заказчик не производит отдельную плату);</li>
                            <li>2.2.7. После перевода или апостиля нотариус загружает документы Заказчика на Платформу;</li>
                            <li>2.2.8. После чего документы направляются в выбранный Заказчиком ВУЗ;</li>
                            <li>2.2.9. ВУЗ рассматривает заявку;</li>
                            <li> 2.2.10. ВУЗ загружает на Онлайн платформу одно из следующих документов: </li>
                            <p className="text_p2">- Контракт на оказание платных образовательных услуг; </p>
                            <p className="text_p2">- Свидетельство/справка о зачислении;</p>
                            <p className="text_p2">- Уведомление о том, что Заказчик может быть принят в ВУЗ после осуществления оплаты по контракту на оказание платных образовательных услуг.</p>
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>3. ПРАВО НА ИНТЕЛЛЕКТУАЛЬНУЮ СОБСТВЕННОСТЬ</h1>
                        <ul>
                            <li>3.1. Правообладатель платформы обладает исключительным правом на Платформу, под которой понимается универсальный программный комплекс информатизации для сбора, для подачи документов в государственные и негосударственные высшие учебные заведения зарубежных стран в электронной форме, размещенный в сети Интернет по адресу: https://edugate.uz/.</li>    
                            <li>3.2. При использовании Платформы, Заказчик соглашается, что обозначенное программное обеспечение является интеллектуальной собственностью и результатом ранее осуществленной интеллектуальной деятельности Правообладателя платформы и не может являться полностью или частично предметом имущественного, интеллектуального или любого другого права со стороны Заказчика, а также не может быть признано результатом интеллектуальной деятельности Заказчика. </li>    
                            <li>3.3.Заказчик не вправе копировать, изменять или повторно использовать Платформу, обновления к ней или ее часть, включая содержащееся в ней программное обеспечение. Заказчик может использовать Платформу исключительно в законных целях в соответствии с настоящими условиями Договора. Заказчик не вправе копировать, перепечатывать, изменять, размещать, выполнять, переводить, распространять, адаптировать, транслировать, передавать общественности с помощью телекоммуникаций, рассылать или продавать контент, каким-либо образом полученный с Платформы, для каких-либо коммерческих целей или предоставлять его какому-либо коммерческому источнику, включая другие веб-сайты, без предварительного письменного разрешения Исполнителя. </li>    
                            <li>3.4. Правообладатель платформы не несет ответственности за ущерб или убыток, понесенный Заказчиком в связи с использованием Платформы в коммерческих или иных целях, не предусмотренных условиями настоящего Договора. </li>    
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h1>
                        <ul>
                            <li>4.1.1. Строго соблюдать конфиденциальность информации, полученной от Заказчика при исполнении настоящего Договора;</li>
                            <li>4.1.2. Предоставить Заказчику возможность получения консультаций, связанных с регистрацией личного кабинета пользователя по телефонам, указанным на сайте www.edugate.uz .</li>
                            <li>4.1.3. Не разглашать без разрешения Заказчика персональные данные Заказчика.</li>
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>4.2. Заказчик обязуется:</h1>
                        <ul>
                            <li>4.2.1. До момента заключения Договора, регистрации личного кабинета и внесения персональных данных (собственных персональных данных или персональных данных иных лиц, давших на это согласие по настоящему договору) пользователя ознакомиться с содержанием Договора на сайте www.edugate.uz;</li>
                            <li>4.2.2. Предоставлять достоверную информацию о себе (ФИО, контактные телефоны, адрес электронной почты, реквизиты и др.);</li>
                            <li>4.2.3. Не использовать программы-роботы, программы-обходчики или иные автоматические устройства, процессы или способы получения доступа к Платформе для каких бы то ни было целей, включая накопление, сбор данных, контроль или копирование какого-либо материала на Платформе; 4.2.4. Не использовать иным образом никакое устройство, программное обеспечение или процедуру, которая нарушает нормальную работу Платформы;</li>
                            <li>4.2.5. Не пытаться иным образом вмешиваться в нормальную работу Платформы. Заказчик настоящим предупрежден об ответственности за незаконный (несанкционированный) доступ к компьютерной информации (ст.2782 Уголовного кодекса РУз.).</li>
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1> 5. СТОИМОСТЬ И ПОРЯДОК ОПЛАТЫ</h1>
                        <ul>
                            <li>5.1. Платформа посредством искусственного интеллекта высчитывает стоимость услуг в зависимости от выбранного Заказчиком ВУЗа и направления.</li>
                            <li>5.2. Заказчик производит оплату в течении 2 (двух) рабочих дней с помощью ЭПС (Click, Payme, путем банковского перечисления или иным путем). В случае неуплаты услуг по настоящему договору, Платформа автоматически приостанавливает все действия, которые Заказчик должен будет произвести для поступления в ВУЗ.</li>
                            <li>5.3. Отказ от услуг Правообладателя платформы после произведения оплаты Заказчиком и оказания Услуг Правообладателем платформы не является основанием для возврата денежных средств, уплаченных по настоящему Договору.</li>
                            <li>5.4. В случае отказа Заказчиком от услуг Правообладателя платформы после осуществления оплаты по настоящему договору, при том, что Заказчику оказаны услуги, предусмотренные п.п. 2.2. – 2.2.10 настоящего договора, денежные средства возврату не подлежат.</li>
                            <li>5.4. В случае отказа Заказчиком от услуг Правообладателя платформы после осуществления оплаты по настоящему договору, при том, что Заказчику не оказаны услуги, предусмотренные п.п. 2.2.9-2.2.10 настоящего договора, денежные средства подлежат возврату Заказчику с условием удержания 30 % предоплаты по настоящему договору.</li>
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>6. ОТВЕТСТВЕННОСТЬ СТОРОН</h1>
                        <ul>
                            <li>6.1. Стороны несут ответственность за невыполнение либо ненадлежащее выполнение своих обязательств по настоящему Договору в соответствии с действующим законодательством Республики Узбекистан.</li> 
                            <li>6.2. Правообладатель платформы не несет ответственности за несвоевременное предоставление документов Заказчику третьими лицами. </li>
                            <li>6.3. Правообладатель платформы не несет ответственности, если ожидания Заказчика относительно   функциональных свойств, удобства пользовательского контента или любых других функций, характеристик системы и потребительских свойств Платформы оказались неоправданными.</li>
                            <li>6.4. Заказчик не несет ответственности за частичное или полное неисполнение обязательств в рамках оказания Услуг Правообладателю платформы, если они являются следствием форс-мажорных обстоятельств. </li>
                            <li>6.5. Заказчик несет ответственность за достоверность предоставляемой информации о себе и о других лицах, давших на это согласие, а также подтверждает, что с условиями настоящего Договора ознакомлен и согласен, а также проинформирован об этом Договоре и ознакомил с ним других лиц, давших согласие на сбор, хранение и обработку персональных данных в рамках Платформы.</li>
                            <li>6.6. Заказчик ознакомился и согласен с Условиями оказания Услуг, размещенными в сети Интернет по адресу www.edugate.uz;</li>
                            <li>6.7. Заказчик обязуется прочитать обо всех условиях оказания Услуг. Заказчик не может ссылаться на свою не информированность об условиях оказания Услуг. </li>

                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>7.  ФОРС-МАЖОР</h1>
                        <ul>
                            <li>7.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему Договору, если оно явилось следствием форс-мажорных обстоятельств: пожар, стихийные бедствия, состояние войны, военные операции любого характера, блокады, запрещение экспорта и импорта, пандемии, эпидемии, действия и решения правительства или другие, не зависящие от Сторон обстоятельства, возникшие после подписания настоящего Договора.</li>
                            <li>7.2. Сторона, для которой создалась невозможность исполнения обязательств по настоящему Договору, не позднее 5 дней с даты их наступления письменно или по факсу извещает другую Сторону о наступлении и прекращении вышеуказанных обстоятельств.</li>
                            <li>7.3. Надлежащим подтверждением наличия форс-мажорных обстоятельств и их продолжительности будет служить справка, выдаваемая компетентным органом, если иное не вытекает из существа Форс-мажорных обстоятельств.</li>
                            <li>7.4. В случаях наступления обстоятельств, предусмотренных в п. 6.1, срок выполнения стороной обязательств по настоящему Договору отодвигается соразмерно времени, в течение которого действуют эти обстоятельства.</li>
                            <li>7.5. Если наступившие обстоятельства, перечисленные в п. 6.1. продолжают действовать более одного месяца, Стороны имеют право расторгнуть настоящий Договор в одностороннем порядке.</li>
        
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>8. КОНФИДЕНЦИАЛЬНОСТЬ</h1>
                        <ul>
                            <li>8.1. Стороны обязуются обеспечить конфиденциальность информации, ставшей известной в рамках исполнения настоящего Договора.</li>
                            <li>8.2. Стороны обязуются не передавать Конфиденциальную информацию третьим лицам.</li>
                            <li>8.3. Сторона обязуется сообщать другой Стороне о факте несанкционированного разглашения конфиденциальной информации. </li>
                            <li>8.4. Правообладатель платформы не несет ответственность за убытки, причиненные Заказчику в результате разглашения третьим лицам Конфиденциальной информации Заказчика, произошедшего не по вине Правообладателя платформы.</li>

                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>9. РАЗРЕШЕНИЕ СПОРОВ</h1>
                        <ul>
                            <li>9.1. Все споры, возникающие при исполнении настоящего Договора, решаются Сторонами путем переговоров.</li>
                            <li>9.2. В случае не достижения согласия, спорные вопросы подлежат разрешению в Учтепинском межрайонном суде по гражданским делам в порядке, установленном законодательством Республики Узбекистан.</li>

                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>10. СРОК ДЕЙСТВИЯ И ИЗМЕНЕНИЯ ДОГОВОРА</h1>
                        <ul>
                        <li>10.1. Настоящая оферта вступает в силу с момента ее акцепта и действует:</li>
                            <p className="text_p2">а) до момента исполнения Сторонами обязательств по Договору, а именно оплаты Заказчиком стоимости Услуг и оказания Правообладателем платформы Услуг в объеме, соответствующем стоимости Услуг;</p>
                            <p className="text_p2">б) до момента расторжения Договора.</p>
                        <li>10.2. В случае отзыва Оферты в течение срока действия Договора, Договор считается прекращенным с момента отзыва, если иное не оговорено Правообладателем платформы при отзыве Оферты.</li>
        
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>10.3. Договор может быть расторгнут в следующих случаях:</h1>
                        <ul>
                            <li>10.3.1. по соглашению Сторон;</li>
                            <li>10.3.2. по инициативе любой из Сторон в случае нарушения другой Стороной условий Договора с письменным уведомлением по электронной почте другой Стороны за 10 (десять) рабочих дней до такого расторжения;</li>
                            <li>10.3.3. по инициативе Заказчика в случае неисполнения Исполнителем обязательств по настоящему Договору;</li>
                            <li>10.3.4. по иным основаниям, предусмотренным действующим законодательством Республики Узбекистан. </li>
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h1>
                        <ul>
                            <li>11.1. Настоящая оферта вступает в силу с момента ее акцепта и действует до полного исполнения Сторонами своих обязательств.</li>
                            <li>11.2. Услуги по настоящему контракту считаются выполненными в полном объеме с момента отправления ВУЗом Заказчику контракта на оказание платных образовательных услуг или Свидетельство о зачислении, либо Уведомление о том, что Заказчик может быть принят в ВУЗ после осуществления оплаты по контракту на оказание платных образовательных услуг.</li>
                            <li>11.3. Стороны признают, что все уведомления, сообщения, соглашения, документы и письма, направленные с использованием уполномоченных адресов электронной почты и Личного кабинета на Платформе, считаются направленными и подписанными Сторонами, кроме случаев, когда в таких письмах прямо не указано обратное.</li>
                        </ul>
                    </div>
                    <div className="textD_Block1">
                        <h1></h1>
                    </div>

                    <div className="textD_bottom">
                        <p>ООО «ASIA CAMPUS»</p>
                        <p>Адрес: г. Ташкент, Шайхантахурский </p>
                        <p>район, улица Фурката, дом 16.   </p>                
                        <p>Банк: Шайхантахурский филиал </p>
                        <p>АКИБ «Ипотека банк» </p>
                        <p>Р/С 2020 8000 9053 3858 3001 </p>
                        <p>МФО: 00425    <span className="textDspan1"> ИНН: 308116441</span></p>
                        <p>Телефон: +998 90 969 66 24;  +998 90 328 93 60</p>
                        <p>Директор       <span className="textDspan1"> И.А.Бегимкулов</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TextDogovor
