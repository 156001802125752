import React from "react";
import TableAccountant from "../../Accountant/Pages/TableAccountant";

export default function AccountantTicket() {
  return (
    <div>
      <div className="accountant-ticket">
        <TableAccountant></TableAccountant>
      </div>
    </div>
  );
}
