import React, { useEffect } from 'react'
import Navbar2 from '../Navbar2'
import Footer from '../../footer/footer2'
import { Container } from '@mui/material'
import './unverlist.css'
import { NavLink, useHistory } from 'react-router-dom'
import Axios from '../../../../utils/axios'
import { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios'
import img1 from '../../../../assets/university/group.png'
import img2 from '../../../../assets/university/back view of woman working on laptop.png'
import img3 from '../../../../assets/university/young man with laptop on chair.png'
import img4 from '../../../../assets/university/man woman chatting with speech bubble and web browser windows.png'
import img5 from '../../../../assets/university/young woman student with smartphone sitting on steps.png'
import img6 from '../../../../assets/university/man and woman working at the table.png'
import { useTranslation } from 'react-i18next'



const UnverList2 = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [unver, setUnver] = useState([])
    const [search, setSearch] = useState('')
    const [search2, setSearch2] = useState('')
    const [search3, setSearch3] = useState('')

    const searchUnver = () => {

        Axios.get(`/university/uzbuniversity/?limit=10000&&q=${search}`).then((res) => {
            setUnver(res.data.results);
        })

    }
    const searchUnver2 = () => {

        axios.get(`https://backend.edugately.com/api/v1/university/uzbuniversity/?limit=10000&&location=${search2}`).then((res) => {
            setUnver(res.data.results);
        })

    }

    const searchUnver3 = () => {

        axios.get(`https://backend.edugately.com/api/v1/university/uzbuniversity/?limit=10000&&major=${search3}`).then((res) => {
            setUnver(res.data.results);
        })

    }

    useEffect(() => {
        axios.get(`https://backend.edugately.com/api/v1/university/uzbuniversity/?limit=2000`).then((res) => {
            setUnver(res.data.results);
        })

    }, [])

    useEffect(() => {
        if (search.length > 3) {
            axios.get(`https://backend.edugately.com/api/v1/university/uzbuniversity/?limit=10000&&q=${search}`).then((res) => {
                setUnver(res.data.results);
            })
        }
    }, [search])
    useEffect(() => {
        if (search2.length > 3) {
            axios.get(`https://backend.edugately.com/api/v1/university/uzbuniversity/?limit=10000&&location=${search2}`).then((res) => {
                setUnver(res.data.results);
            })
        }
    }, [search2])

    useEffect(() => {
        if (search3.length > 3) {
            axios.get(`https://backend.edugately.com/api/v1/university/uzbuniversity/?limit=10000&&major=${search3}`).then((res) => {
                setUnver(res.data.results);
            })
        }
    }, [search3])


    return (

        <div>
            <Navbar2 status={true} />
            <div className="container-fluid max-width px-4 px-lg-5 mt-5 ">
                <div className="row">
                    <div className="col-12">
                        <h1 className="section_title text-pr">
                            {t('unveruz1')}
                        </h1>
                    </div>
                </div>
                <div className="row partner-university-info ml-0">
                    <div className="col-12 col-lg-7 first-info">
                        <div className="row parent">
                            <div className="col-5 left info-card">
                                <div className="info-count">
                                    <div className="svg-div">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} viewBox="0 0 28 28" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.5002 2.33398H19.8335C22.0334 2.33398 23.1333 2.33398 23.8167 3.0174C24.5002 3.70082 24.5002 4.80076 24.5002 7.00065V24.7923H25.6668C26.1501 24.7923 26.5418 25.1841 26.5418 25.6673C26.5418 26.1506 26.1501 26.5423 25.6668 26.5423H2.3335C1.85025 26.5423 1.4585 26.1506 1.4585 25.6673C1.4585 25.1841 1.85025 24.7923 2.3335 24.7923H3.50016V10.5007C3.50016 8.30076 3.50016 7.20082 4.18358 6.5174C4.867 5.83398 5.96694 5.83398 8.16683 5.83398H12.8335C15.0334 5.83398 16.1333 5.83398 16.8167 6.5174C17.5002 7.20082 17.5002 8.30076 17.5002 10.5007V24.7923H19.2502V10.5007L19.2502 10.3962C19.2503 9.38634 19.2504 8.4604 19.1491 7.70717C19.0376 6.87771 18.7752 6.00102 18.0542 5.27997C17.3331 4.55891 16.4564 4.29653 15.627 4.18502C14.8844 4.08517 13.9738 4.08387 12.9805 4.08397C13.0788 3.63219 13.243 3.29133 13.5169 3.0174C14.2003 2.33398 15.3003 2.33398 17.5002 2.33398ZM6.12516 9.33398C6.12516 8.85074 6.51691 8.45898 7.00016 8.45898H14.0002C14.4834 8.45898 14.8752 8.85074 14.8752 9.33398C14.8752 9.81723 14.4834 10.209 14.0002 10.209H7.00016C6.51691 10.209 6.12516 9.81723 6.12516 9.33398ZM6.12516 12.834C6.12516 12.3507 6.51691 11.959 7.00016 11.959H14.0002C14.4834 11.959 14.8752 12.3507 14.8752 12.834C14.8752 13.3172 14.4834 13.709 14.0002 13.709H7.00016C6.51691 13.709 6.12516 13.3172 6.12516 12.834ZM6.12516 16.334C6.12516 15.8507 6.51691 15.459 7.00016 15.459H14.0002C14.4834 15.459 14.8752 15.8507 14.8752 16.334C14.8752 16.8172 14.4834 17.209 14.0002 17.209H7.00016C6.51691 17.209 6.12516 16.8172 6.12516 16.334ZM10.5002 21.2923C10.9834 21.2923 11.3752 21.6841 11.3752 22.1673V24.7923H9.62516V22.1673C9.62516 21.6841 10.0169 21.2923 10.5002 21.2923Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className="number">
                                        <h6>100+</h6>
                                        <p>{t('unverlistban1')}</p>
                                    </div>
                                    <svg className="small-svg" xmlns="http://www.w3.org/2000/svg" width={33} height={12} viewBox="0 0 33 12" fill="none">
                                        <path d="M1.6001 10.5475C1.6001 10.5475 4.18649 5.72143 7.15799 7.38317C10.1295 9.0449 10.5146 10.5475 13.1559 9.55047C15.7973 8.55343 15.9626 0.126836 18.2738 1.78857C20.585 3.45031 26.2897 6.77378 31.2422 1.78857" stroke="url(#paint0_linear_1047_2936)" strokeWidth="2.37343" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1047_2936" x1="20.342" y1="-0.632016" x2="14.2197" y2="14.8313" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#AAFFF7" />
                                                <stop offset={1} stopColor="white" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="img-div">
                                    <img src={img1} alt={404} />
                                </div>
                            </div>
                            <div className="col-7 right">
                                <div className="row r-1 info-card">
                                    <div className="info-count">
                                        <div className="svg-div">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={19} viewBox="0 0 18 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.59835 2.74335C1.5 3.90272 1.5 5.7687 1.5 9.50065C1.5 13.2326 1.5 15.0986 2.59835 16.2579C3.6967 17.4173 5.46447 17.4173 9 17.4173C12.5355 17.4173 14.3033 17.4173 15.4017 16.2579C16.5 15.0986 16.5 13.2326 16.5 9.50065C16.5 5.7687 16.5 3.90272 15.4017 2.74335C14.8886 2.20183 14.2295 1.91324 13.3125 1.75945V9.36688C13.3125 9.73703 13.3125 10.0407 13.2995 10.2796C13.2866 10.5152 13.2589 10.7593 13.1672 10.9773C12.8362 11.764 12.0194 12.1883 11.2269 11.9852C11.0073 11.9289 10.8072 11.8031 10.6225 11.6701C10.4352 11.5353 10.2073 11.35 9.9294 11.1242L9.9155 11.1129C9.5635 10.8268 9.46443 10.7518 9.37357 10.7101C9.13528 10.6008 8.86472 10.6008 8.62643 10.7101C8.53557 10.7518 8.4365 10.8268 8.0845 11.1129L8.0706 11.1242C7.79274 11.35 7.56484 11.5353 7.37754 11.6701C7.19279 11.8031 6.99267 11.9289 6.77315 11.9852C5.9806 12.1883 5.16385 11.764 4.83279 10.9773C4.74109 10.7593 4.71341 10.5152 4.70054 10.2796C4.68749 10.0406 4.6875 9.73702 4.6875 9.36686L4.6875 1.75945C3.77045 1.91324 3.11138 2.20183 2.59835 2.74335ZM5.25 13.6569C4.93934 13.6569 4.6875 13.9227 4.6875 14.2507C4.6875 14.5786 4.93934 14.8444 5.25 14.8444H12.75C13.0607 14.8444 13.3125 14.5786 13.3125 14.2507C13.3125 13.9227 13.0607 13.6569 12.75 13.6569H5.25Z" fill="white" />
                                                <path d="M5.8125 1.6384V9.34833C5.8125 9.74158 5.81272 10.0107 5.82368 10.2112C5.83502 10.419 5.85577 10.483 5.86093 10.4952C5.97128 10.7575 6.24353 10.8989 6.50771 10.8312C6.52006 10.828 6.58071 10.8064 6.74361 10.6891C6.90084 10.5759 7.10297 10.4119 7.39815 10.172L7.44839 10.1311C7.72603 9.90516 7.94243 9.72905 8.17814 9.62095C8.70238 9.38054 9.29762 9.38054 9.82186 9.62095C10.0576 9.72905 10.274 9.90514 10.5516 10.1311L10.6019 10.172C10.897 10.4119 11.0992 10.5759 11.2564 10.6891C11.4193 10.8064 11.4799 10.828 11.4923 10.8312C11.7565 10.8989 12.0287 10.7575 12.1391 10.4952C12.1442 10.483 12.165 10.419 12.1763 10.2112C12.1873 10.0107 12.1875 9.74158 12.1875 9.34833V1.6384C11.3207 1.58398 10.2753 1.58398 9 1.58398C7.72467 1.58398 6.67935 1.58398 5.8125 1.6384Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="number">
                                            <h6>2200+</h6>
                                            <p>{t('unverlistban2')}</p>
                                        </div>
                                    </div>
                                    <div className="img-div">
                                        <img src={img2} alt={404} />
                                    </div>
                                </div>
                                <div className="row r-2 info-card">
                                    <div className="info-count">
                                        <div className="svg-div">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19" fill="none">
                                                <path d="M11.0835 1.58398H7.91683C4.93127 1.58398 3.43849 1.58398 2.51099 2.51148C1.5835 3.43897 1.5835 4.93176 1.5835 7.91732V8.70898C1.5835 9.14568 1.5835 9.53979 1.58866 9.89648H9.97653C10.0586 9.35874 10.2424 8.79817 10.7076 8.33304C11.1968 7.84375 11.7917 7.66571 12.3546 7.59004C12.8657 7.52132 13.494 7.5214 14.1793 7.52148H14.321C15.0063 7.5214 15.6346 7.52132 16.1457 7.59004C16.5709 7.64721 17.0144 7.76279 17.4168 8.02684V7.91732C17.4168 4.93176 17.4168 3.43897 16.4893 2.51148C15.5618 1.58398 14.0691 1.58398 11.0835 1.58398Z" fill="white" />
                                                <path d="M2.27912 12.7634C2.97474 13.459 4.09432 13.459 6.3335 13.459H8.90641V16.6257H6.3335C6.00558 16.6257 5.73975 16.8915 5.73975 17.2194C5.73975 17.5473 6.00558 17.8132 6.3335 17.8132H10.7277L10.7076 17.7933C10.2183 17.304 10.0402 16.7091 9.96455 16.1462C9.89583 15.6351 9.89591 15.0068 9.89599 14.3215V11.8048C9.89594 11.4098 9.8959 11.0338 9.90903 10.6882H1.61386C1.66973 11.684 1.82842 12.3127 2.27912 12.7634Z" fill="white" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M17.4168 11.8757V14.2507C17.4168 15.7434 17.4168 16.4898 16.9531 16.9536C16.4893 17.4173 15.7429 17.4173 14.2502 17.4173C12.7574 17.4173 12.011 17.4173 11.5472 16.9536C11.0835 16.4898 11.0835 15.7434 11.0835 14.2507V11.8757C11.0835 10.3829 11.0835 9.63648 11.5472 9.17273C12.011 8.70898 12.7574 8.70898 14.2502 8.70898C15.7429 8.70898 16.4893 8.70898 16.9531 9.17273C17.4168 9.63648 17.4168 10.3829 17.4168 11.8757ZM12.8647 15.834C12.8647 15.5061 13.1306 15.2402 13.4585 15.2402H15.0418C15.3697 15.2402 15.6356 15.5061 15.6356 15.834C15.6356 16.1619 15.3697 16.4277 15.0418 16.4277H13.4585C13.1306 16.4277 12.8647 16.1619 12.8647 15.834Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="number">
                                            <h6>70+</h6>
                                            <p>{t('unverlistban3')}</p>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={33} height={11} viewBox="0 0 33 11" fill="none">
                                            <path d="M31.1107 9.54471C31.1107 9.54471 28.3802 4.21345 25.4276 5.94569C22.475 7.67793 19.6866 7.76679 14.9979 2.8347C10.3091 -2.09738 7.1372 8.00802 2.0341 3.139" stroke="url(#paint0_linear_1056_3018)" strokeWidth="2.37343" strokeLinecap="round" strokeLinejoin="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_1056_3018" x1="3.41799" y1="-2.89745" x2="11.7594" y2="17.4417" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white" stopOpacity="0.01" />
                                                    <stop offset={1} stopColor="white" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="img-div">
                                        <img src={img3} alt={404} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 secont-info">
                        <div className="row parent">
                            <div className="col-5 left info-card">
                                <div className="info-count">
                                    <div className="svg-div">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={18} viewBox="0 0 19 18" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M1.5835 12.107V3.74762C1.5835 2.92399 2.28515 2.25491 3.1529 2.30543C3.92586 2.35044 4.84001 2.4394 5.54183 2.61485C6.37245 2.8225 7.35958 3.26477 8.14028 3.65586C8.38341 3.77765 8.64195 3.86213 8.90655 3.90974V15.2937C8.66668 15.2436 8.43265 15.1628 8.21166 15.0512C7.4204 14.6516 6.39798 14.1883 5.54183 13.9743C4.84777 13.8008 3.94605 13.7119 3.17859 13.6664C2.30078 13.6144 1.5835 12.9402 1.5835 12.107ZM4.10264 6.20356C3.78451 6.12822 3.46215 6.31146 3.38261 6.61284C3.30308 6.91423 3.4965 7.21963 3.81463 7.29497L6.9813 8.04497C7.29943 8.12032 7.62179 7.93708 7.70132 7.63569C7.78086 7.33431 7.58744 7.02891 7.26931 6.95356L4.10264 6.20356ZM4.10264 9.20356C3.78451 9.12822 3.46215 9.31146 3.38261 9.61284C3.30308 9.91423 3.4965 10.2196 3.81463 10.295L6.9813 11.045C7.29943 11.1203 7.62179 10.9371 7.70132 10.6357C7.78086 10.3343 7.58744 10.0289 7.26931 9.95356L4.10264 9.20356Z" fill="white" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.0941 15.2937C10.3338 15.2436 10.5678 15.1628 10.7887 15.0512C11.5799 14.6516 12.6023 14.1883 13.4585 13.9743C14.1526 13.8008 15.0543 13.7119 15.8217 13.6664C16.6995 13.6144 17.4168 12.9402 17.4168 12.107V3.69916C17.4168 2.89483 16.7469 2.23458 15.8984 2.26258C15.0049 2.29206 13.8915 2.37979 13.0627 2.61485C12.3441 2.81864 11.5073 3.22741 10.8253 3.60133C10.5946 3.72783 10.348 3.82075 10.0941 3.87967V15.2937ZM15.186 7.29497C15.5041 7.21963 15.6975 6.91423 15.618 6.61284C15.5385 6.31146 15.2161 6.12822 14.898 6.20356L11.7313 6.95356C11.4132 7.02891 11.2197 7.33431 11.2993 7.63569C11.3788 7.93708 11.7012 8.12032 12.0193 8.04497L15.186 7.29497ZM15.186 10.295C15.5041 10.2196 15.6975 9.91423 15.618 9.61284C15.5385 9.31146 15.2161 9.12822 14.898 9.20356L11.7313 9.95356C11.4132 10.0289 11.2197 10.3343 11.2993 10.6357C11.3788 10.9371 11.7012 11.1203 12.0193 11.045L15.186 10.295Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className="number">
                                        <h6>50000+</h6>
                                        <p>{t('unverlistban4')}</p>
                                    </div>
                                </div>
                                <div className="img-div">
                                    <img src={img4} alt={404} />
                                </div>
                            </div>
                            <div className="col-7 right">
                                <div className="row r-1 info-card">
                                    <div className="info-count">
                                        <div className="svg-div">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.61762 14.1437C4.16137 13.2936 5.3256 13.3215 5.90369 14.0614C6.68744 15.0647 7.78148 16.0312 9.49981 16.0312C11.2483 16.0312 12.3258 15.2563 13.0662 14.2666C13.6362 13.5046 14.8758 13.3802 15.4734 14.2844C15.9682 15.0331 16.505 15.62 17.5527 15.8669C17.8718 15.9421 18.0696 16.2618 17.9944 16.581C17.9192 16.9002 17.5995 17.0979 17.2803 17.0227C15.8141 16.6773 15.0576 15.809 14.4827 14.9392C14.4376 14.8709 14.3699 14.8363 14.282 14.838C14.1881 14.8397 14.0863 14.8854 14.0171 14.9779C13.0927 16.2136 11.685 17.2188 9.49981 17.2188C7.24944 17.2188 5.84911 15.9205 4.9679 14.7925C4.91111 14.7198 4.83701 14.6921 4.77328 14.6935C4.71316 14.6948 4.65817 14.7207 4.61798 14.7836C4.03255 15.6988 3.27661 16.6558 1.71932 17.0227C1.40014 17.0979 1.08043 16.9002 1.00522 16.581C0.93001 16.2618 1.12779 15.9421 1.44696 15.8669C2.55604 15.6056 3.09331 14.9634 3.61762 14.1437Z" fill="white" />
                                                <path d="M17.5527 11.9086C16.505 11.6617 15.9682 11.0747 15.4734 10.3261C14.9189 9.48711 13.8118 9.53369 13.1996 10.1534L12.2706 9.10417L7.1254 3.27289C6.69608 2.78633 5.94526 2.76289 5.48642 3.22172C5.07077 3.63737 5.04521 4.30297 5.42776 4.74927L6.2097 5.66154C6.68821 6.2198 5.91904 7.45263 5.91904 7.45263L3.95684 9.81641C3.82886 9.91266 3.71358 10.0353 3.61762 10.1854C3.09331 11.0051 2.55604 11.6472 1.44696 11.9086C1.12779 11.9838 0.93001 12.3035 1.00522 12.6227C1.08043 12.9418 1.40014 13.1396 1.71932 13.0644C3.27661 12.6975 4.03255 11.7405 4.61798 10.8252C4.65817 10.7624 4.71316 10.7365 4.77328 10.7352C4.83701 10.7338 4.91111 10.7615 4.9679 10.8342C5.84911 11.9622 7.24944 13.2604 9.49981 13.2604C11.685 13.2604 13.0927 12.2553 14.0171 11.0196C14.0863 10.9271 14.1881 10.8814 14.282 10.8796C14.3699 10.878 14.4376 10.9126 14.4827 10.9808C15.0576 11.8506 15.8141 12.7189 17.2803 13.0644C17.5995 13.1396 17.9192 12.9418 17.9944 12.6227C18.0696 12.3035 17.8718 11.9838 17.5527 11.9086Z" fill="white" />
                                                <path d="M13.4581 6.33333C14.5512 6.33333 15.4373 5.44723 15.4373 4.35417C15.4373 3.2611 14.5512 2.375 13.4581 2.375C12.3651 2.375 11.479 3.2611 11.479 4.35417C11.479 5.44723 12.3651 6.33333 13.4581 6.33333Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="number">
                                            <h6>37+</h6>
                                            <p>{t('unverlistban5')}</p>
                                        </div>
                                    </div>
                                    <div className="img-div">
                                        <img src={img5} alt={404} />
                                    </div>
                                </div>
                                <div className="row r-2 info-card">
                                    <div className="info-count">
                                        <div className="svg-div">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} viewBox="0 0 16 17" fill="none">
                                                <path d="M8 0.583984C9.38391 0.583984 10.497 0.583984 11.4051 0.653516L8.82396 4.74025H5.30104L7.92605 0.583984H8Z" fill="white" />
                                                <path d="M1.59835 1.74335C2.53762 0.751896 3.96645 0.608303 6.57175 0.587506L3.94896 4.74025H0.578135C0.688572 3.34368 0.955102 2.42234 1.59835 1.74335Z" fill="white" />
                                                <path d="M0.5 8.50065C0.5 7.52156 0.5 6.67091 0.519833 5.92775H15.4802C15.5 6.67091 15.5 7.52156 15.5 8.50065C15.5 8.56932 15.5 8.63737 15.5 8.70478C15.0751 8.38461 14.596 8.24725 14.1266 8.18063C13.5914 8.10468 12.9302 8.10475 12.1943 8.10482H12.0557C11.3198 8.10475 10.6586 8.10468 10.1235 8.18063C9.53918 8.26355 8.93976 8.45608 8.44877 8.97435C7.95778 9.49262 7.77538 10.1253 7.69682 10.7421C7.62487 11.307 7.62493 12.0049 7.625 12.7817V12.928C7.62493 13.7048 7.62487 14.4027 7.69682 14.9676C7.75993 15.463 7.89007 15.9688 8.19339 16.4173C8.12952 16.4173 8.06506 16.4173 8 16.4173C4.46447 16.4173 2.6967 16.4173 1.59835 15.2579C0.5 14.0986 0.5 12.2326 0.5 8.50065Z" fill="white" />
                                                <path d="M15.4219 4.74025C15.3114 3.34368 15.0449 2.42234 14.4017 1.74335C13.9537 1.27047 13.3943 0.990468 12.649 0.824672L10.176 4.74025H15.4219Z" fill="white" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.125 16.4173C10.534 16.4173 9.73851 16.4173 9.24426 15.8956C8.75 15.3739 8.75 14.5342 8.75 12.8548C8.75 11.1754 8.75 10.3357 9.24426 9.81403C9.73851 9.29232 10.534 9.29232 12.125 9.29232C13.716 9.29232 14.5115 9.29232 15.0057 9.81403C15.5 10.3357 15.5 11.1754 15.5 12.8548C15.5 14.5342 15.5 15.3739 15.0057 15.8956C14.5115 16.4173 13.716 16.4173 12.125 16.4173ZM13.784 11.1036C14.072 11.4075 14.072 11.9003 13.784 12.2043L13.7407 12.2501C13.6988 12.2942 13.6404 12.3143 13.5821 12.3032C13.5454 12.2963 13.4919 12.2834 13.4279 12.26C13.3001 12.2132 13.1322 12.1247 12.9744 11.9582C12.8167 11.7916 12.7329 11.6145 12.6885 11.4795C12.6663 11.412 12.6541 11.3555 12.6476 11.3168C12.6371 11.2553 12.6561 11.1936 12.6979 11.1494L12.7413 11.1036C13.0292 10.7997 13.4961 10.7997 13.784 11.1036ZM12.0125 14.0742C11.9043 14.1884 11.8502 14.2456 11.7905 14.2947C11.7202 14.3526 11.644 14.4023 11.5634 14.4428C11.4951 14.4772 11.4225 14.5027 11.2773 14.5538L10.5117 14.8232C10.4403 14.8483 10.3615 14.8287 10.3082 14.7725C10.255 14.7163 10.2364 14.6331 10.2602 14.5577L10.5154 13.7496C10.5638 13.5963 10.588 13.5197 10.6206 13.4476C10.659 13.3625 10.706 13.2822 10.7609 13.2079C10.8075 13.1449 10.8616 13.0878 10.9698 12.9736L12.3189 11.5495C12.3515 11.5151 12.4077 11.5308 12.4228 11.5768C12.4781 11.7449 12.5818 11.9636 12.7756 12.1681C12.9693 12.3727 13.1765 12.4821 13.3357 12.5405C13.3793 12.5564 13.3942 12.6157 13.3616 12.6502L12.0125 14.0742Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="number">
                                            <h6>100+</h6>
                                            <p>{t('unverlistban6')}</p>
                                        </div>
                                    </div>
                                    <div className="img-div">
                                        <img src={img6} alt={404} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid max-width px-5 my-5 universities oldd">
                <h1 className=" text-dark section_title text-pr d-blcok d-xl-none">{t('unverlistban1')}</h1>
                <div className="row parent ">
                    <div className="col-12 col-xl-9 parent-cards p-02">
                        <h1 className="text-dark section_title text-pr mt-5 mb-5 d-none d-xl-block">{t('unverlistban1')}</h1>
                        {/* pagenation 20 tadan owmasn */}
                        <div className="row cards">
                            {
                                unver?.map((item, index) => {
                                    return (
                                        <div className="col-6 col-xl-3 " key={index} onClick={() => history.push(`/uzbuniversity/${item?.id}/`)}>
                                            <div className="card">
                                                <div className="img-div">
                                                    <img src={item.icon} alt="" />
                                                </div>
                                                {item?.name?.length > 55 ? (
                                                        <h5>{item.name.substring(0, 50)}...</h5>
                                                    ) : (
                                                        <h5>{item?.name}</h5>
                                                    )}
                                                <div className="stars">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={152} height={24} viewBox="0 0 152 24" fill="none">
                                                        <path d="M11.1033 1.81699C11.4701 1.07374 12.5299 1.07374 12.8967 1.81699L15.294 6.67446C15.4397 6.9696 15.7213 7.17417 16.047 7.2215L21.4075 8.00043C22.2277 8.11961 22.5552 9.12759 21.9617 9.70612L18.0828 13.4871C17.8471 13.7169 17.7396 14.0479 17.7952 14.3723L18.7109 19.7111C18.851 20.528 17.9936 21.151 17.26 20.7653L12.4653 18.2446C12.174 18.0915 11.826 18.0915 11.5347 18.2446L6.74005 20.7653C6.00642 21.151 5.14899 20.528 5.2891 19.7111L6.20479 14.3723C6.26043 14.0479 6.15288 13.7169 5.91719 13.4871L2.03827 9.70612C1.44476 9.12759 1.77226 8.11961 2.59249 8.00043L7.95302 7.2215C8.27873 7.17417 8.5603 6.9696 8.70596 6.67446L11.1033 1.81699Z" fill="#FF8C4B" />
                                                        <path d="M43.1033 1.81699C43.4701 1.07374 44.5299 1.07374 44.8967 1.81699L47.294 6.67446C47.4397 6.9696 47.7213 7.17417 48.047 7.2215L53.4075 8.00043C54.2277 8.11961 54.5552 9.12759 53.9617 9.70612L50.0828 13.4871C49.8471 13.7169 49.7396 14.0479 49.7952 14.3723L50.7109 19.7111C50.851 20.528 49.9936 21.151 49.26 20.7653L44.4653 18.2446C44.174 18.0915 43.826 18.0915 43.5347 18.2446L38.7401 20.7653C38.0064 21.151 37.149 20.528 37.2891 19.7111L38.2048 14.3723C38.2604 14.0479 38.1529 13.7169 37.9172 13.4871L34.0383 9.70612C33.4448 9.12759 33.7723 8.11961 34.5925 8.00043L39.953 7.2215C40.2787 7.17417 40.5603 6.9696 40.706 6.67446L43.1033 1.81699Z" fill="#FF8C4B" />
                                                        <path d="M75.1033 1.81699C75.4701 1.07374 76.5299 1.07374 76.8967 1.81699L79.294 6.67446C79.4397 6.9696 79.7213 7.17417 80.047 7.2215L85.4075 8.00043C86.2277 8.11961 86.5552 9.12759 85.9617 9.70612L82.0828 13.4871C81.8471 13.7169 81.7396 14.0479 81.7952 14.3723L82.7109 19.7111C82.851 20.528 81.9936 21.151 81.26 20.7653L76.4653 18.2446C76.174 18.0915 75.826 18.0915 75.5347 18.2446L70.7401 20.7653C70.0064 21.151 69.149 20.528 69.2891 19.7111L70.2048 14.3723C70.2604 14.0479 70.1529 13.7169 69.9172 13.4871L66.0383 9.70612C65.4448 9.12759 65.7723 8.11961 66.5925 8.00043L71.953 7.2215C72.2787 7.17417 72.5603 6.9696 72.706 6.67446L75.1033 1.81699Z" fill="#FF8C4B" />
                                                        <path d="M107.103 1.81699C107.47 1.07374 108.53 1.07374 108.897 1.81699L111.294 6.67446C111.44 6.9696 111.721 7.17417 112.047 7.2215L117.408 8.00043C118.228 8.11961 118.555 9.12759 117.962 9.70612L114.083 13.4871C113.847 13.7169 113.74 14.0479 113.795 14.3723L114.711 19.7111C114.851 20.528 113.994 21.151 113.26 20.7653L108.465 18.2446C108.174 18.0915 107.826 18.0915 107.535 18.2446L102.74 20.7653C102.006 21.151 101.149 20.528 101.289 19.7111L102.205 14.3723C102.26 14.0479 102.153 13.7169 101.917 13.4871L98.0383 9.70612C97.4448 9.12759 97.7723 8.11961 98.5925 8.00043L103.953 7.2215C104.279 7.17417 104.56 6.9696 104.706 6.67446L107.103 1.81699Z" fill="#FF8C4B" />
                                                        <path d="M139.103 1.81699C139.47 1.07374 140.53 1.07374 140.897 1.81699L143.294 6.67446C143.44 6.9696 143.721 7.17417 144.047 7.2215L149.408 8.00043C150.228 8.11961 150.555 9.12759 149.962 9.70612L146.083 13.4871C145.847 13.7169 145.74 14.0479 145.795 14.3723L146.711 19.7111C146.851 20.528 145.994 21.151 145.26 20.7653L140.465 18.2446C140.174 18.0915 139.826 18.0915 139.535 18.2446L134.74 20.7653C134.006 21.151 133.149 20.528 133.289 19.7111L134.205 14.3723C134.26 14.0479 134.153 13.7169 133.917 13.4871L130.038 9.70612C129.445 9.12759 129.772 8.11961 130.592 8.00043L135.953 7.2215C136.279 7.17417 136.56 6.9696 136.706 6.67446L139.103 1.81699Z" fill="#C4C4C4" />
                                                    </svg>
                                                </div>
                                                <div className="location">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.25 10.1433C3.25 5.24427 7.15501 1.25 12 1.25C16.845 1.25 20.75 5.24427 20.75 10.1433C20.75 12.5084 20.076 15.0479 18.8844 17.2419C17.6944 19.4331 15.9556 21.3372 13.7805 22.3539C12.6506 22.882 11.3494 22.882 10.2195 22.3539C8.04437 21.3372 6.30562 19.4331 5.11556 17.2419C3.92403 15.0479 3.25 12.5084 3.25 10.1433ZM12 2.75C8.00843 2.75 4.75 6.04748 4.75 10.1433C4.75 12.2404 5.35263 14.5354 6.4337 16.526C7.51624 18.5192 9.04602 20.1496 10.8546 20.995C11.5821 21.335 12.4179 21.335 13.1454 20.995C14.954 20.1496 16.4838 18.5192 17.5663 16.526C18.6474 14.5354 19.25 12.2404 19.25 10.1433C19.25 6.04748 15.9916 2.75 12 2.75ZM12 7.75C10.7574 7.75 9.75 8.75736 9.75 10C9.75 11.2426 10.7574 12.25 12 12.25C13.2426 12.25 14.25 11.2426 14.25 10C14.25 8.75736 13.2426 7.75 12 7.75ZM8.25 10C8.25 7.92893 9.92893 6.25 12 6.25C14.0711 6.25 15.75 7.92893 15.75 10C15.75 12.0711 14.0711 13.75 12 13.75C9.92893 13.75 8.25 12.0711 8.25 10Z" fill="#C4C4C4" stroke="#C4C4C4" strokeWidth="0.5" />
                                                    </svg>
                                                    <p>{t('p299')}</p>
                                                </div>
                                            </div>
                                        </div>                                       
                                    )
                                })
                            }
                           
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 sticky-search p-02" id="search-universities">
                        <div className="search" id="searchDiv">
                            <div className="text">
                                <h4>{t('unveruz2')}</h4>
                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                                    <g clipPath="url(#clip0_867_4120)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.25 3C8.25 3.41421 7.91421 3.75 7.5 3.75L2.25 3.75C1.83578 3.75 1.5 3.41421 1.5 3C1.5 2.58579 1.83578 2.25 2.25 2.25L7.5 2.25C7.91421 2.25 8.25 2.58579 8.25 3Z" fill="#64748B" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.5 3C16.5 3.41421 16.1642 3.75 15.75 3.75L10.5 3.75C10.0858 3.75 9.75 3.41421 9.75 3C9.75 2.58579 10.0858 2.25 10.5 2.25L15.75 2.25C16.1642 2.25 16.5 2.58579 16.5 3Z" fill="#64748B" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.75 9C9.75 9.41421 9.41421 9.75 9 9.75L2.25 9.75C1.83579 9.75 1.5 9.41421 1.5 9C1.5 8.58579 1.83579 8.25 2.25 8.25L9 8.25C9.41421 8.25 9.75 8.58579 9.75 9Z" fill="#64748B" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.5 9C16.5 9.41421 16.1642 9.75 15.75 9.75L12 9.75C11.5858 9.75 11.25 9.41421 11.25 9C11.25 8.58579 11.5858 8.25 12 8.25L15.75 8.25C16.1642 8.25 16.5 8.58579 16.5 9Z" fill="#64748B" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.75 15C6.75 15.4142 6.41421 15.75 6 15.75L2.25 15.75C1.83578 15.75 1.5 15.4142 1.5 15C1.5 14.5858 1.83578 14.25 2.25 14.25L6 14.25C6.41421 14.25 6.75 14.5858 6.75 15Z" fill="#64748B" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.5 15C16.5 15.4142 16.1642 15.75 15.75 15.75L9 15.75C8.58579 15.75 8.25 15.4142 8.25 15C8.25 14.5858 8.58579 14.25 9 14.25L15.75 14.25C16.1642 14.25 16.5 14.5858 16.5 15Z" fill="#64748B" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 -3.27835e-08C7.91421 -1.46777e-08 8.25 0.335786 8.25 0.75L8.25 5.25C8.25 5.66421 7.91421 6 7.5 6C7.08579 6 6.75 5.66421 6.75 5.25L6.75 0.75C6.75 0.335786 7.08579 -5.08894e-08 7.5 -3.27835e-08Z" fill="#64748B" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 6C12.4142 6 12.75 6.33579 12.75 6.75L12.75 11.25C12.75 11.6642 12.4142 12 12 12C11.5858 12 11.25 11.6642 11.25 11.25L11.25 6.75C11.25 6.33579 11.5858 6 12 6Z" fill="#64748B" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6 12C6.41421 12 6.75 12.3358 6.75 12.75L6.75 17.25C6.75 17.6642 6.41421 18 6 18C5.58579 18 5.25 17.6642 5.25 17.25L5.25 12.75C5.25 12.3358 5.58579 12 6 12Z" fill="#64748B" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_867_4120">
                                            <rect width={18} height={18} fill="white" transform="translate(18) rotate(90)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="accordion mt-5" id="accordionExample">
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button bg-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <p className="text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                                                    <path d="M6.10322 0.957031H2.53135C1.5751 0.957031 0.787598 1.74453 0.787598 2.70078V6.27266C0.787598 7.22891 1.5751 8.01641 2.53135 8.01641H6.10322C7.05947 8.01641 7.84697 7.22891 7.84697 6.27266V2.72891C7.8751 1.74453 7.0876 0.957031 6.10322 0.957031ZM6.60947 6.30078C6.60947 6.58203 6.38447 6.80703 6.10322 6.80703H2.53135C2.2501 6.80703 2.0251 6.58203 2.0251 6.30078V2.72891C2.0251 2.44766 2.2501 2.22266 2.53135 2.22266H6.10322C6.38447 2.22266 6.60947 2.44766 6.60947 2.72891V6.30078Z" fill="#DEE4EE" />
                                                    <path d="M15.4689 0.957031H11.8971C10.9408 0.957031 10.1533 1.74453 10.1533 2.70078V6.27266C10.1533 7.22891 10.9408 8.01641 11.8971 8.01641H15.4689C16.4252 8.01641 17.2127 7.22891 17.2127 6.27266V2.72891C17.2127 1.74453 16.4252 0.957031 15.4689 0.957031ZM15.9752 6.30078C15.9752 6.58203 15.7502 6.80703 15.4689 6.80703H11.8971C11.6158 6.80703 11.3908 6.58203 11.3908 6.30078V2.72891C11.3908 2.44766 11.6158 2.22266 11.8971 2.22266H15.4689C15.7502 2.22266 15.9752 2.44766 15.9752 2.72891V6.30078Z" fill="#DEE4EE" />
                                                    <path d="M6.10322 9.92969H2.53135C1.5751 9.92969 0.787598 10.7172 0.787598 11.6734V15.2453C0.787598 16.2016 1.5751 16.9891 2.53135 16.9891H6.10322C7.05947 16.9891 7.84697 16.2016 7.84697 15.2453V11.7016C7.8751 10.7172 7.0876 9.92969 6.10322 9.92969ZM6.60947 15.2734C6.60947 15.5547 6.38447 15.7797 6.10322 15.7797H2.53135C2.2501 15.7797 2.0251 15.5547 2.0251 15.2734V11.7016C2.0251 11.4203 2.2501 11.1953 2.53135 11.1953H6.10322C6.38447 11.1953 6.60947 11.4203 6.60947 11.7016V15.2734Z" fill="#DEE4EE" />
                                                    <path d="M15.4689 9.92969H11.8971C10.9408 9.92969 10.1533 10.7172 10.1533 11.6734V15.2453C10.1533 16.2016 10.9408 16.9891 11.8971 16.9891H15.4689C16.4252 16.9891 17.2127 16.2016 17.2127 15.2453V11.7016C17.2127 10.7172 16.4252 9.92969 15.4689 9.92969ZM15.9752 15.2734C15.9752 15.5547 15.7502 15.7797 15.4689 15.7797H11.8971C11.6158 15.7797 11.3908 15.5547 11.3908 15.2734V11.7016C11.3908 11.4203 11.6158 11.1953 11.8971 11.1953H15.4689C15.7502 11.1953 15.9752 11.4203 15.9752 11.7016V15.2734Z" fill="#DEE4EE" />
                                                </svg>
                                                {t('unveruz3')}
                                            </p>
                                        </div>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show body-part" data-bs-parent="#accordionExample">
                                        <div className="accordion-body p-0">
                                            <div className=" sort">
                                            <input type="text" onClick={() => { searchUnver() }} onChange={(e) => { setSearch(e.target.value) }} placeholder={t('unveruz4')} />
                                            </div>
                                            <div className=" sort">
                                            <input type="text" onClick={() => { searchUnver2() }} onChange={(e) => { setSearch2(e.target.value) }} placeholder={t('unveruz5')} />
                                            </div>
                                            <div className=" sort">
                                            <input type="text" onClick={() => { searchUnver3() }} onChange={(e) => { setSearch3(e.target.value) }} placeholder={t('unveruz6')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="search-div d-flex">
                                <label htmlFor="search-univer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#4B6A97" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.957 11.958C12.2499 11.6651 12.7247 11.6651 13.0176 11.958L16.2801 15.2205C16.573 15.5133 16.573 15.9882 16.2801 16.2811C15.9872 16.574 15.5124 16.574 15.2195 16.2811L11.957 13.0186C11.6641 12.7257 11.6641 12.2508 11.957 11.958Z" fill="#4B6A97" />
                                    </svg>
                                </label>
                                <input className type="text" id="search-univer" />
                            </div> */}
                        </div>
                    </div>
                    <div className="d-none d-xl-block col-12 pagination">
                        <ul>
                            <li className="active">1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                        </ul>
                    </div>
                </div>
                <div className="d-blcok d-xl-none col-12 pagination">
                    <ul>
                        <li className="active">1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UnverList2